import {
  registerSeller as registerSellerApi,
  createStore as createStoreApi,
  createProduct as createProductApi,
  getBrands as getBrandsApi,
  getCategories as categoriesApi,
  getBrand as getBrandApi,
  getBlockedBrands,
  getPendingBrands,
  getActivatedBrands,
  getPage,
  togglePublishState,
  getAllListings,
  getNewListings,
  fetchBanks as banksApi,
  // getBrandDetails as getBrandDetailsApi,
  // getBrandStats as getBrandStatsApi,
  // getBrandPayment as getBrandPaymentApi,
  // getBrandBankInfo as getBrandBankInfoApi,
  // getBrandDeliveryOption as getDeliveryOptionApi,
  // getBrandReturnsOption as getReturnsOptionApi,
  // getBrandSizeGuide as getBrandSizeGuideApi,
  // getBrandVacationMode as getVacationModeApi,
  updateBrandDetails as updateBrandDetailsApi,
  searchBrand as searchBrandApi,
  getProduct as getProductApi,
  getBrandListings as getBrandListingsApi,
  getApprovedProducts as approvedProductsApi,
  getNewProducts as newProductsApi,
  getBrandsPaginated as brandsPaginatedApi,
  resetBrandPassword as resetBrandPasswordApi,
  updateBrands as updateBrandsApi,
  updateBrandDelivery as updateBrandDeliveryApi,
  updateBrandReturns as updateBrandReturnsApi,
  activateVacationMode as activateVacationModeApi,
  deactivateVacationMode as deactivateVacationModeApi,
  activateBrand as activateBrandApi,
  deactivateBrand as deactivateBrandApi,
  blockBrand as blockBrandApi,
  unblockBrand as unblockBrandApi,
  uploadSizeGuide as uploadSizeGuideApi,
  deleteSizeGuide as deleteSizeGuideApi,
  activateProduct as activateProductApi,
  deactivateProduct as deactivateProductApi,
  approveProduct as approveProductApi,
  rejectProduct as rejectProductApi,
  updateProduct as updateProductApi,
  updateVariant as updateVariantApi,
  addVariant as addVariantApi,
  deleteVariant as deleteVariantApi,
  deleteProduct as deleteProductApi,
  swapImage as swapImageApi,
  deleteImage as deleteImageApi,
  fetchBrandApplications as fetchBrandApplicationsApi,
  fetchBrandApplicationDetails as fetchBrandApplicationDetailsApi,
  admimApproveBrand,
  updateQuantity,
  updateNewSellerInfoApi,
  getPhotoshootBookings,
  bookPhotoshoot,
  getPhotoshootDetails,
  getBrandDetailsAndInformation as getBrandDetailsAndInformationApi,
} from "../../api/brandsApi";

import {
  REGISTER_STORE_START,
  REGISTER_STORE_FAIL,
  GET_BRANDS,
  GET_BRAND,
  GET_BRAND_DETAILS,
  REGISTER_SELLER_SUCCESS,
  REGISTER_SELLER_FAIL,
  UPDATE_STORE_FAILED,
  CREATE_PRODUCT_FAILED,
  ACTIVATE_BRAND_FAILED,
  DEACTIVATE_BRAND_FAILED,
  GET_NEW_PRODUCTS,
  GET_APPROVED_PRODUCTS,
  GET_BRAND_PRODUCTS,
  GET_BRAND_PRODUCTS_FAILED,
  GET_APPROVED_PRODUCTS_FAILED,
  GET_NEW_PRODUCTS_FAILED,
  GET_PRODUCT,
  GET_PRODUCT_FAILED,
  SEARCH_BRAND,
  SEARCH_BRAND_DONE,
  GET_BANKS,
  GET_CATEGORIES,
  DELETE_PRODUCT_FAILED,
  ACTIVATE_PRODUCT_FAILED,
  DEACTIVATE_PRODUCT_FAILED,
  GET_ALL_PRODUCTS,
  GET_PENDING_BRANDS,
  GET_BLOCKED_BRANDS,
  GET_ACTIVATED_BRANDS,
  FETCH_BRAND_APPLICATIONS,
  FETCH_BRAND_APPLICATIONS_FAILED,
  FETCH_BRAND_APPLICATION_DETAILS,
  FETCH_BRAND_APPLICATIONS_DETAILS_FAILED,
  UPDATE_NEW_SELLER_INFO_FAILED,
  GET_PHOTOSHOOTS,
  GET_PHOTOSHOOT_DETAILS,
} from "./type";

export const registerSeller = (token, values) => {
  return async (dispatch) => {
    try {
      const res = await registerSellerApi(token, values);

      if (res.status === "ok") {
        dispatch({
          type: REGISTER_SELLER_SUCCESS,
          payload: res.data,
          error: false,
        });
      }
      if (res.status === "not ok") {
        dispatch({
          type: REGISTER_SELLER_FAIL,
          payload: res.message,
          error: true,
        });
      }

      return res;
    } catch (err) {
      dispatch({
        type: REGISTER_SELLER_FAIL,
        payload: err.message,
        error: true,
      });
    }
  };
};

export const createStore = (token, values) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: REGISTER_STORE_START,
      });
      const res = await createStoreApi(token, values);
      if (res.status === "not ok") {
        dispatch({
          type: REGISTER_STORE_FAIL,
          payload: res.message,
          error: true,
        });
      }
      return res;
    } catch (err) {
      dispatch({
        type: REGISTER_STORE_FAIL,
        payload: err.message,
        error: true,
      });
    }
  };
};
export const createProduct = (values, token) => {
  return async (dispatch) => {
    try {
      const res = await createProductApi(values, token);
      if (res.status === "ok") {
        const store = await getBrandsApi(token);
        dispatch({
          type: GET_BRANDS,
          payload: store,
          error: false,
        });
      }
      return res;
    } catch (err) {
      dispatch({
        type: CREATE_PRODUCT_FAILED,
        payload: err.message,
        error: true,
      });
    }
  };
};

export const fetchBrands = (token) => {
  return async (dispatch) => {
    try {
      const res = await getBrandsApi(token);
      if (res.status === "ok") {
        dispatch({
          type: GET_BRANDS,
          payload: res.data,
          error: false,
        });
      }
    } catch (err) {
      console.log("error", err.message);
    }
  };
};
export const fetchPendingBrands = (token) => {
  return async (dispatch) => {
    try {
      const res = await getPendingBrands(token);
      if (res.status === "ok") {
        dispatch({
          type: GET_PENDING_BRANDS,
          payload: res,
          error: false,
        });
      }
    } catch (err) {
      console.log("error", err.message);
    }
  };
};

export const fetchActivatedBrands = (token) => {
  return async (dispatch) => {
    try {
      const res = await getActivatedBrands(token);
      if (res.status === "ok") {
        dispatch({
          type: GET_ACTIVATED_BRANDS,
          payload: res,
          error: false,
        });
      }
    } catch (err) {
      console.log("error", err.message);
    }
  };
};

export const fetchNextPendingBrands = (token, url) => {
  return async (dispatch) => {
    try {
      const res = await getPage(token, url);
      if (res.status === "ok") {
        dispatch({
          type: GET_PENDING_BRANDS,
          payload: res,
          error: false,
        });
      }
    } catch (err) {
      console.log("error", err.message);
    }
  };
};
export const fetchNextBlockedBrands = (token, url) => {
  return async (dispatch) => {
    try {
      const res = await getPage(token, url);
      if (res.status === "ok") {
        dispatch({
          type: GET_BLOCKED_BRANDS,
          payload: res,
          error: false,
        });
      }
    } catch (err) {
      console.log("error", err.message);
    }
  };
};

export const fetchBlockedBrands = (token) => {
  return async (dispatch) => {
    try {
      const res = await getBlockedBrands(token);
      if (res.status === "ok") {
        dispatch({
          type: GET_BLOCKED_BRANDS,
          payload: res,
          error: false,
        });
      }
    } catch (err) {
      console.log("error", err.message);
    }
  };
};
export const fetchListings = (token) => {
  return async (dispatch) => {
    try {
      const res = await getAllListings(token);
      if (res.status === "ok") {
        dispatch({
          type: GET_ALL_PRODUCTS,
          payload: res,
          error: false,
        });
      }
    } catch (err) {
      console.log("error", err.message);
    }
  };
};
export const fetchNextListings = (token, url) => {
  return async (dispatch) => {
    try {
      const res = await getPage(token, url);
      if (res.status === "ok") {
        dispatch({
          type: GET_ALL_PRODUCTS,
          payload: res,
          error: false,
        });
      }
    } catch (err) {
      console.log("error", err.message);
    }
  };
};
export const fetchNewListings = (token) => {
  return async (dispatch) => {
    try {
      const res = await getNewListings(token);
      if (res.status === "ok") {
        dispatch({
          type: GET_ALL_PRODUCTS,
          payload: res,
          error: false,
        });
      }
    } catch (err) {
      console.log("error", err.message);
    }
  };
};

export const fetchCategories = (token) => {
  return async (dispatch) => {
    try {
      const res = await categoriesApi(token);
      dispatch({
        type: GET_CATEGORIES,
        payload: res,
        error: false,
      });
    } catch (err) {
      console.log("error", err.message);
    }
  };
};
export const fetchBrandsPaginated = (token, page) => {
  return async (dispatch) => {
    try {
      const res = await brandsPaginatedApi(token, page);
      dispatch({
        type: GET_BRANDS,
        payload: res,
        error: false,
      });
    } catch (err) {
      console.log("error", err.message);
    }
  };
};

export const fetchBrand = (token, sellerId) => {
  return async (dispatch) => {
    try {
      const res = await getBrandApi(token, sellerId);
      dispatch({
        type: GET_BRAND,
        payload: res,
        error: false,
      });
    } catch (err) {
      console.log("error", err.message);
    }
  };
};
export const togglePublish = (token, sellerId) => {
  return async (dispatch) => {
    try {
      const res = await togglePublishState(token, sellerId);
      if (res.status === "ok") {
        dispatch(fetchBrand(token, sellerId));
      }
      return res;
    } catch (err) {
      console.log("error", err.message);
    }
  };
};

export const fetchBrandDetails = (token, sellerId) => {
  return async (dispatch) => {
    try {
      const response = await getBrandDetailsAndInformationApi(token, sellerId);

      const data = {
        ...response.store_detail,
        ...response.store_dasboard_count,
        ...response.store_bank_detail,
        ...response.store_payment_detail,
        ...response.store_size_guide,


        deliveryOption: response?.store_delivery,
        returnType: response?.store_returns,
        vacationMode: response?.store_on_vacation,
      };
      // delete data.store_delivery
      // delete data.store_returns
      // delete data.store_on_vacation

      console.log("DATA FOR BRAND DEATILS", data)

      dispatch({
        type: GET_BRAND_DETAILS,
        payload: data,
        error: false,
      });
    } catch (err) {
      console.log("error", err.message);
    }
  };
};

export const searchBrand = (token, keyword) => {
  return async (dispatch) => {
    try {
      const res = await searchBrandApi(token, keyword);
      if (res.status === "ok") {
        dispatch({
          type: SEARCH_BRAND,
          payload: res.data,
          error: false,
        });

        setTimeout(() => dispatch({ type: SEARCH_BRAND_DONE }), 5000);
      }
    } catch (err) {
      console.log("error", err.message);
    }
  };
};
export const fetchBrandListings = (token, sellerId, page) => {
  return async (dispatch) => {
    try {
      const res = await getBrandListingsApi(token, sellerId, page);
      if (res.status === "ok") {
        dispatch({
          type: GET_BRAND_PRODUCTS,
          payload: res,
          error: false,
        });
      }
      if (res.status === "not ok") {
        dispatch({
          type: GET_BRAND_PRODUCTS_FAILED,
          error: true,
          payload: res.message,
        });
      }
    } catch (err) {
      dispatch({
        type: GET_BRAND_PRODUCTS_FAILED,
        error: true,
        payload: err.message,
      });
    }
  };
};
export const fetchApprovedProducts = (token, sellerId) => {
  return async (dispatch) => {
    try {
      const res = await approvedProductsApi(token, sellerId);
      if (res.status === "ok") {
        dispatch({
          type: GET_APPROVED_PRODUCTS,
          payload: res,
          error: false,
        });
      }
      if (res.status === "not ok") {
        dispatch({
          type: GET_APPROVED_PRODUCTS_FAILED,
          error: true,
          payload: res.message,
        });
      }
    } catch (err) {
      dispatch({
        type: GET_APPROVED_PRODUCTS_FAILED,
        error: true,
        payload: err.message,
      });
    }
  };
};
export const fetchNewProducts = (token, sellerId) => {
  return async (dispatch) => {
    try {
      const res = await newProductsApi(token, sellerId);
      if (res.status === "ok") {
        dispatch({
          type: GET_NEW_PRODUCTS,
          payload: res,
          error: false,
        });
      }
      if (res.status === "not ok") {
        dispatch({
          type: GET_NEW_PRODUCTS_FAILED,
          error: true,
          payload: res.message,
        });
      }
    } catch (err) {
      dispatch({
        type: GET_NEW_PRODUCTS_FAILED,
        error: true,
        payload: err.message,
      });
    }
  };
};
export const fetchProduct = (token, productId) => {
  return async (dispatch) => {
    try {
      const res = await getProductApi(token, productId);
      if (res.status === "ok") {
        dispatch({
          type: GET_PRODUCT,
          payload: res.data,
          error: false,
        });
      }
      if (res.status === "not ok") {
        dispatch({
          type: GET_PRODUCT_FAILED,
          error: true,
          payload: res.message,
        });
      }
      return res;
    } catch (err) {
      dispatch({
        type: GET_PRODUCT_FAILED,
        error: true,
        payload: err.message,
      });
    }
  };
};
export const updateProduct = (token, values) => {
  return async (dispatch) => {
    try {
      const res = await updateProductApi(token, values);
      if (res.status === "ok") {
        fetchProduct(token, values.seller_id, values.product_id);
      }
      return res;
    } catch (err) {
      dispatch({
        type: GET_PRODUCT_FAILED,
        error: true,
        payload: err.message,
      });
    }
  };
};
export const addVariant = (token, variantId, sellerId, productId) => {
  return async (dispatch) => {
    try {
      const res = await addVariantApi(token, variantId);
      if (res.status === "ok") {
        fetchProduct(token, sellerId, productId);
      }
      return res;
    } catch (err) {
      dispatch({
        type: GET_PRODUCT_FAILED,
        error: true,
        payload: err.message,
      });
    }
  };
};
export const updateVariant = (token, variantId, sellerId, productId) => {
  return async (dispatch) => {
    try {
      const res = await updateVariantApi(token, variantId);
      if (res.status === "ok") {
        fetchProduct(token, sellerId, productId);
      }
      return res;
    } catch (err) {
      dispatch({
        type: GET_PRODUCT_FAILED,
        error: true,
        payload: err.message,
      });
    }
  };
};

export const fetchBanks = (token) => {
  return async (dispatch) => {
    try {
      const res = await banksApi(token);
      if (res.status === "success") {
        const sortedBanks = res.data.sort(function (a, b) {
          return a.name.localeCompare(b.name);
        });
        dispatch({
          type: GET_BANKS,
          payload: sortedBanks,
          error: false,
        });
      }
    } catch (err) {
      console.log(err.message);
    }
  };
};

export const updateStoreDetails = (token, values) => {
  return async (dispatch) => {
    try {
      const res = await updateBrandsApi(values, token);
      if (res.status === "ok") {
        dispatch(fetchBrandDetails(token, +values.sellerId));
      }

      return res;
    } catch (err) {
      dispatch({
        type: UPDATE_STORE_FAILED,
        error: true,
        payload: err.message,
      });
    }
  };
};
export const resetBrandPassword = (token, sellerId) => {
  return async (dispatch) => {
    try {
      const res = await resetBrandPasswordApi(token, sellerId);

      return res;
    } catch (err) {
      dispatch({
        type: UPDATE_STORE_FAILED,
        error: true,
        payload: err.message,
      });
    }
  };
};
export const updateBrandDetails = (id, values, token) => {
  return async (dispatch) => {
    try {
      const res = await updateBrandDetailsApi(id, values, token);
      if (res.status === "ok") {
        dispatch(fetchBrandDetails(token, +values.sellerId));
      }
      return res;
    } catch (err) {
      dispatch({
        type: UPDATE_STORE_FAILED,
        error: true,
        payload: err.message,
      });
    }
  };
};
export const updateBrandDelivery = (token, values) => {
  return async (dispatch) => {
    try {
      const res = await updateBrandDeliveryApi(values, token);
      if (res.status === "ok") {
        dispatch(fetchBrandDetails(token, +values.sellerId));
      }
      return res;
    } catch (err) {
      dispatch({
        type: UPDATE_STORE_FAILED,
        error: true,
        payload: err.message,
      });
    }
  };
};
export const updateBrandReturns = (token, values) => {
  return async (dispatch) => {
    try {
      const res = await updateBrandReturnsApi(values, token);
      if (res.status === "ok") {
        dispatch(fetchBrandDetails(token, +values.sellerId));
      }
      return res;
    } catch (err) {
      dispatch({
        type: UPDATE_STORE_FAILED,
        error: true,
        payload: err.message,
      });
    }
  };
};
export const activateVacationMode = (token, values) => {
  return async (dispatch) => {
    try {
      const res = await activateVacationModeApi(token, values);
      if (res.status === "ok") {
        dispatch(fetchBrandDetails(token, +values.sellerId));
      }
      return res;
    } catch (err) {
      dispatch({
        type: UPDATE_STORE_FAILED,
        error: true,
        payload: err.message,
      });
    }
  };
};

export const addSizeGuide = (token, values) => {
  return async (dispatch) => {
    try {
      const res = await uploadSizeGuideApi(token, values);
      if (res.status === "ok") {
        dispatch(fetchBrandDetails(token, +values.sellerId));
      }
      return res;
    } catch (err) {
      dispatch({
        type: UPDATE_STORE_FAILED,
        error: true,
        payload: err.message,
      });
    }
  };
};
export const delSizeGuide = (token, sellerId) => {
  return async (dispatch) => {
    try {
      const res = await deleteSizeGuideApi(token, sellerId);
      if (res.status === "ok") {
        dispatch(fetchBrandDetails(token, sellerId));
      }
      return res;
    } catch (err) {
      dispatch({
        type: UPDATE_STORE_FAILED,
        error: true,
        payload: err.message,
      });
    }
  };
};

export const deactivateVacationMode = (token, sellerId) => {
  return async (dispatch) => {
    try {
      const res = await deactivateVacationModeApi(token, sellerId);
      if (res.status === "ok") {
        dispatch(fetchBrandDetails(token, sellerId));
      }
      return res;
    } catch (err) {
      dispatch({
        type: UPDATE_STORE_FAILED,
        error: true,
        payload: err.message,
      });
    }
  };
};

export const activateBrand = (token, brandId, storeId, publish) => {
  return async (dispatch) => {
    try {
      const res = await activateBrandApi(token, { brandId, publish });
      if (res.status === "ok") {
        dispatch(fetchBrand(token, storeId));
      }
      if (res.status === "not ok") {
        dispatch({
          type: ACTIVATE_BRAND_FAILED,
          error: true,
          payload: res.message,
        });
      }
      return res;
    } catch (err) {
      dispatch({
        type: ACTIVATE_BRAND_FAILED,
        error: true,
        payload: err.message,
      });
    }
  };
};
export const deactivateBrand = (token, brandId, storeId) => {
  return async (dispatch) => {
    try {
      const res = await deactivateBrandApi(token, brandId);
      if (res.status === "ok") {
        dispatch(fetchBrand(token, storeId));
      }
      if (res.status === "not ok") {
        dispatch({
          type: DEACTIVATE_BRAND_FAILED,
          error: true,
          payload: res.message,
        });
      }
      return res;
    } catch (err) {
      dispatch({
        type: DEACTIVATE_BRAND_FAILED,
        error: true,
        payload: err.message,
      });
    }
  };
};
export const blockBrand = (token, values) => {
  return async (dispatch) => {
    try {
      const res = await blockBrandApi(token, values);
      if (res.status === "ok") {
        dispatch(fetchBrand(token, values.sellerId));
      }
      if (res.status === "not ok") {
        dispatch({
          type: DEACTIVATE_BRAND_FAILED,
          error: true,
          payload: res.message,
        });
      }
      return res;
    } catch (err) {
      dispatch({
        type: DEACTIVATE_BRAND_FAILED,
        error: true,
        payload: err.message,
      });
    }
  };
};
export const unblockBrand = (token, sellerId) => {
  return async (dispatch) => {
    try {
      const res = await unblockBrandApi(token, sellerId);
      if (res.status === "ok") {
        dispatch(fetchBrand(token, sellerId));
      }
      if (res.status === "not ok") {
        dispatch({
          type: DEACTIVATE_BRAND_FAILED,
          error: true,
          payload: res.message,
        });
      }
      return res;
    } catch (err) {
      dispatch({
        type: DEACTIVATE_BRAND_FAILED,
        error: true,
        payload: err.message,
      });
    }
  };
};
export const approveProduct = (token, productId) => {
  return async (dispatch) => {
    try {
      const res = await activateProductApi(token, productId);
      if (res.status === "ok") {
        dispatch(fetchProduct(token, productId));
      }

      return res;
    } catch (err) {
      dispatch({
        type: ACTIVATE_PRODUCT_FAILED,
        error: true,
        payload: err.message,
      });
    }
  };
};
export const rejectProduct = (token, productId, reason) => {
  return async (dispatch) => {
    try {
      const res = await rejectProductApi(token, productId, reason);
      if (res.status === "ok") {
        dispatch(fetchProduct(token, productId));
      }

      return res;
    } catch (err) {
      dispatch({
        type: ACTIVATE_PRODUCT_FAILED,
        error: true,
        payload: err.message,
      });
    }
  };
};
export const activateProduct = (token, productId) => {
  return async (dispatch) => {
    try {
      const res = await approveProductApi(token, productId);
      if (res.status === "ok") {
        dispatch(fetchProduct(token, productId));
      }
      if (res.status === "not ok") {
        dispatch({
          type: ACTIVATE_PRODUCT_FAILED,
          error: true,
          payload: res.message,
        });
      }
      return res;
    } catch (err) {
      dispatch({
        type: ACTIVATE_PRODUCT_FAILED,
        error: true,
        payload: err.message,
      });
    }
  };
};
export const deactivateProduct = (token, productId) => {
  return async (dispatch) => {
    try {
      const res = await deactivateProductApi(token, productId);
      if (res.status === "ok") {
        dispatch(fetchProduct(token, productId));
      }
      if (res.status === "not ok") {
        dispatch({
          type: DEACTIVATE_PRODUCT_FAILED,
          error: true,
          payload: res.message,
        });
      }
      return res;
    } catch (err) {
      dispatch({
        type: DEACTIVATE_PRODUCT_FAILED,
        error: true,
        payload: err.message,
      });
    }
  };
};

export const deleteProduct = (token, id, type, sellerId) => {
  return async (dispatch) => {
    try {
      const res = await deleteProductApi(token, id);
      if (res.status === "ok") {
        if (type === "new") dispatch(fetchNewListings(token));
        if (type === "new") dispatch(fetchListings(token));
        if (type === "") dispatch(fetchBrandListings(token, sellerId));
      }
      return res;
    } catch (err) {
      dispatch({
        type: DELETE_PRODUCT_FAILED,
        error: true,
        payload: err.message,
      });
    }
  };
};

export const updateProductQuantity = (token, values) => {
  return async (dispatch) => {
    try {
      const res = await updateQuantity(token, values);
      if (res.status === "ok") {
        dispatch(fetchProduct(token, values.product_id));
      }
      return res;
    } catch (err) {
      dispatch({
        type: DELETE_PRODUCT_FAILED,
        error: true,
        payload: err.message,
      });
    }
  };
};

export const deleteVariant = (token, variantId, sellerId, productId) => {
  return async (dispatch) => {
    try {
      const res = await deleteVariantApi(token, variantId, productId);
      if (res.status === "ok") {
        fetchProduct(token, sellerId, productId);
      }
      return res;
    } catch (err) {
      dispatch({
        type: GET_PRODUCT_FAILED,
        error: true,
        payload: err.message,
      });
    }
  };
};

export const swapImage = (token, data) => {
  return async (dispatch) => {
    try {
      const res = await swapImageApi(token, data);
      if (res.status === "ok") {
        dispatch(fetchProduct(data.slug));
      }
      return res;
    } catch (err) {
      dispatch({
        type: GET_PRODUCT_FAILED,
        payload: err,
        error: true,
      });
    }
  };
};
export const deleteImage = (token, data) => {
  return async (dispatch) => {
    try {
      const res = await deleteImageApi(token, data);
      if (res.status === "ok") {
        dispatch(fetchProduct(data.slug));
      }
      return res;
    } catch (err) {
      dispatch({
        type: GET_PRODUCT_FAILED,
        payload: err,
        error: true,
      });
    }
  };
};

export const fetchBrandApplications = (type, token) => {
  return async (dispatch) => {
    try {
      const res = await fetchBrandApplicationsApi(type, token);
      dispatch({
        type: FETCH_BRAND_APPLICATIONS,
        payload: res.data,
        error: false,
      });
      return res;
    } catch (err) {
      dispatch({
        type: FETCH_BRAND_APPLICATIONS_FAILED,
        payload: err,
        error: true,
      });
    }
  };
};

export const fetchBrandApplicationDetails = (token, id) => {
  return async (dispatch) => {
    try {
      const res = await fetchBrandApplicationDetailsApi(token, id);
      dispatch({
        type: FETCH_BRAND_APPLICATION_DETAILS,
        payload: res.data,
        error: false,
      });
      return res;
    } catch (err) {
      dispatch({
        type: FETCH_BRAND_APPLICATIONS_DETAILS_FAILED,
        payload: err,
        error: true,
      });
    }
  };
};

export const approveBrandApplication = (token, data) => {
  return async (dispatch) => {
    try {
      const res = await admimApproveBrand(token, data);
      return res;
    } catch (err) {
      dispatch({
        type: FETCH_BRAND_APPLICATIONS_DETAILS_FAILED,
        payload: err,
        error: true,
      });
    }
  };
};

export const updateNewSellerInfo = (token, values, id) => {
  return async (dispatch) => {
    try {
      const res = await updateNewSellerInfoApi(token, values);
      if (res.status === "ok") {
        fetchBrandApplicationDetails(token, id);
      }
      return res;
    } catch (err) {
      dispatch({
        type: UPDATE_NEW_SELLER_INFO_FAILED,
        error: true,
        payload: err.message,
      });
    }
  };
};

export const getPhotoshoots = (token) => {
  return async (dispatch) => {
    try {
      const res = await getPhotoshootBookings(token);
      if (res.status === "ok") {
        dispatch({
          type: GET_PHOTOSHOOTS,
          payload: res.data,
          error: false,
        });
      }
    } catch (err) {
      dispatch({
        type: UPDATE_NEW_SELLER_INFO_FAILED,
        error: true,
        payload: err.message,
      });
    }
  };
};

export const getShootDetails = (token, id) => {
  return async (dispatch) => {
    try {
      const res = await getPhotoshootDetails(token, id);
      if (res.status === "ok") {
        dispatch({
          type: GET_PHOTOSHOOT_DETAILS,
          payload: res.data,
          error: false,
        });
      }
    } catch (err) {}
  };
};

export const bookShoot = (token, values) => {
  return async (dispatch) => {
    try {
      const res = await bookPhotoshoot(token, values);
      return res;
    } catch (err) {}
  };
};
